import React, { useContext, createContext } from "react"
import PropTypes from "prop-types"
import useAuth from "../hooks/auth"

const AppContext = createContext({ auth: {} })

const App = ({ children }) => {
  const auth = useAuth()

  return <AppContext.Provider value={{ auth }}>{children}</AppContext.Provider>
}

App.propTypes = {
  children: PropTypes.node.isRequired,
}

export const useAppState = () => {
  const context = useContext(AppContext)

  if (context === undefined) {
    throw Error("useAppState must be used within AppContext")
  }

  return context
}

export default ({ element }) => <App>{element}</App>
