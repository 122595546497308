module.exports = [{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://950b6529b6b84644993efb0c2a07c502@o108099.ingest.sentry.io/5533457"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Mothership","short_name":"Mothership","start_url":"/","background_color":"#091744","theme_color":"#663399","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e4833aed104cbe032d28f8828a9ee931"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
