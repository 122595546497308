/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import firebaseApp, { db } from "../configs/firebase"
import { isAvailable } from "../configs/buyback"

export const statuses = {
  initial: "initial",
  processing: "processing",
  loggedIn: "loggedIn",
  authStateChanging: "authStateChanging",
  failed: "failed",
}

export const updateIntercom = result => {
  if (typeof window !== "undefined" && window.Intercom) {
    window.Intercom("update", {
      email: result.email,
      user_id: result.uid,
      stakeholder_id: result.uid,
    })
  }
}

const useAuth = () => {
  const [user, setUser] = useState({ status: statuses.initial })

  useEffect(() => {
    let profileUnsubscribe = () => {}

    setUser({ ...user, status: statuses.processing })
    const unsubscribe = firebaseApp.auth().onAuthStateChanged(result => {
      setUser({ ...user, user: result, status: statuses.authStateChanging })
      updateIntercom(result || {})
      if (result) {
        const userDoc = db.collection("profiles").doc(result.uid)
        profileUnsubscribe = userDoc.onSnapshot(doc => {
          let profile = {
            uid: result.uid,
            email: result.email,
            buyback: {},
          }
          if (doc.exists) {
            const val = doc.data()
            if (typeof window !== "undefined" && window.Intercom) {
              window.Intercom("update", {
                email: result.email,
                verified_holder: !!val.isVerified,
              })
            }
            profile = val
          }
          setUser({
            status: statuses.loggedIn,
            user: result,
            profile: {
              ...profile,
              buyback: {
                ...profile.buyback,
                isAvailable:
                  isAvailable || Boolean(profile.buyback.isAvailable),
              },
            },
          })
        })
      } else {
        profileUnsubscribe()
        setUser({ status: statuses.initial, user: null, profile: {} })
      }
    })

    return () => {
      profileUnsubscribe()
      unsubscribe()
    }
  }, [])

  return {
    ...user,
    onSignOut() {
      return firebaseApp.auth().signOut()
    },
  }
}

export default useAuth
