// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-auth-js": () => import("./../../../src/pages/auth.js" /* webpackChunkName: "component---src-pages-auth-js" */),
  "component---src-pages-buyback-guide-js": () => import("./../../../src/pages/buyback/guide.js" /* webpackChunkName: "component---src-pages-buyback-guide-js" */),
  "component---src-pages-buyback-privacy-policy-js": () => import("./../../../src/pages/buyback/privacy-policy.js" /* webpackChunkName: "component---src-pages-buyback-privacy-policy-js" */),
  "component---src-pages-buyback-refund-address-js": () => import("./../../../src/pages/buyback/refund-address.js" /* webpackChunkName: "component---src-pages-buyback-refund-address-js" */),
  "component---src-pages-buyback-terms-of-service-js": () => import("./../../../src/pages/buyback/terms-of-service.js" /* webpackChunkName: "component---src-pages-buyback-terms-of-service-js" */),
  "component---src-pages-buyback-verify-identity-js": () => import("./../../../src/pages/buyback/verify-identity.js" /* webpackChunkName: "component---src-pages-buyback-verify-identity-js" */),
  "component---src-pages-holding-verification-js": () => import("./../../../src/pages/holding-verification.js" /* webpackChunkName: "component---src-pages-holding-verification-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */)
}

