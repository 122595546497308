// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from "firebase/app"
// If you are using v7 or any earlier version of the JS SDK, you should import firebase using namespace import
// import * as firebase from "firebase/app"

// Add the Firebase products that you want to use
import "firebase/auth"
import "firebase/functions"
import "firebase/firestore"
import "firebase/analytics"

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.GATSBY_FIREBASE_API_KEY,
  appId: process.env.GATSBY_FIREBASE_APP_ID,
  authDomain: `${process.env.GATSBY_FIREBASE_PROJECT_ID}.firebaseapp.com`,
  databaseURL: `https://${process.env.GATSBY_FIREBASE_PROJECT_ID}.firebaseio.com`,
  measurementId: process.env.GATSBY_FIREBASE_MEASUREMENT_ID,
  messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
  projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
  storageBucket: `${process.env.GATSBY_FIREBASE_PROJECT_ID}.appspot.com`,
}

const firebaseApp = firebase.initializeApp(firebaseConfig)
if (typeof window !== "undefined") {
  firebaseApp.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
}
export const functions = firebaseApp.functions("europe-west3")
export const db = firebaseApp.firestore()

export default firebaseApp
